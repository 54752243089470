// Dark BG
%has-dark-bg {
    --text-color: var(--white);
    --link-color-hover: var(--white);
    color: var(--text-color);

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6,
    p,
    li,
    figcaption,
    address,
    span,
    a:not([class]) {
        color: var(--text-color);
    }

    blockquote {
        p {

            &:first-of-type {
                &::before {
                    color: var(--white-50);
                }
            }

            &:last-of-type {
                &::after {
                    color: var(--white-50);
                }
            }
        }
    }

    table {
        td {
            color: var(--white);
        }
    }

    .btn {
        @extend %btn-white;
    }

    hr {
        background-color: var(--white);
        border-top: 1px solid var(--white);
        color: var(--white);
    }

    form {
        --form-border-color: var(--white);

        input,
        textarea {
            color: var(--blue-600);
        }
    }
}
