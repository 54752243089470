// BG images
.has-bg-image--net {
	background-image: var(--bg-image-net);
	background-position: center bottom;
	background-size: auto;
	background-repeat: no-repeat;
}

// BG colors
[class*="has-bg-color--"] {
	background-color: var(--section-background-color);
}

.has-bg-color--white {
	--section-background-color: var(--white);
	
	+ .has-bg-color--white {
		padding-top: 0;
	}
}

.has-bg-color--blue-dark {
	@extend %has-dark-bg;
	--section-background-color: var(--blue-dark);
	
	+ .has-bg-color--blue-dark {
		padding-top: 0;
	}
	
	&.has-bg-image--net {
		--bg-image-net: url('../images/bg/net-bottom-blue.svg');
	}
}

.has-bg-color--blue-med {
	@extend %has-dark-bg;
	--section-background-color: var(--blue-med);
	
	+ .has-bg-color--blue-med {
		padding-top: 0;
	}
}

.has-bg-color--blue-light {
	@extend %has-dark-bg;
	--section-background-color: var(--blue-light);
	
	+ .has-bg-color--blue-light {
		padding-top: 0;
	}
}

.has-bg-color--mint {
	--section-background-color: var(--mint);
	
	+ .has-bg-color--mint {
		padding-top: 0;
	}
}

.has-bg-color--sand-dark {
	--section-background-color: var(--sand-dark);
	
	+ .has-bg-color--sand-dark {
		padding-top: 0;
	}
}

.has-bg-color--sand-light {
	--section-background-color: var(--sand-light);
	
	+ .has-bg-color--sand-light {
		padding-top: 0;
	}
}

.has-bg-color--sand-xlight {
	--section-background-color: var(--sand-xlight);
	
	+ .has-bg-color--sand-xlight {
		padding-top: 0;
	}
	
	&.has-bg-image--net {
		--bg-image-net: url('../images/bg/net-bottom-sand.svg');
	}
}

