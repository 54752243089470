/* stylelint-disable */
@import 'https://use.typekit.net/aqt5xzc.css';
@import 'global/variables';
@import 'global/a11y';
@import 'global/buttons';
@import 'global/dark-bg';
@import 'global/bg';

body {
    background-color: var(--blue-dark);
    font-family: var(--font-sans);
    color: var(--white);
    font-weight: var(--fontweight-normal);
}

.login {
    position: relative;
    z-index: 2;

    h1 a,
    .wp-login-logo a {
        background-color: transparent;
        background-image: url('../images/logo/ccul-white.svg');
        background-position: center center;
        background-size: contain;
        border: none;
        height: 100px;
        padding: 24px;
        width: 270px;
    }

    #loginform {
        color: var(--black);

        input {
            &[type="text"],
            &[type="password"] {
                box-shadow: none !important;

                &:focus {
                    outline: 2px solid var(--blue-med);
                    box-shadow: none !important;
                }
            }
        }
    }

    form {
        border-radius: 0.25rem;
        position: relative;

        .button-primary {
            @extend %btn;
            font-family: var(--font-sans);
            margin: 0;
            
            &:hover {
                background-color: var(--salmon);
            }
        }
    }

    #backtoblog,
    #nav {
        a {
            color: var(--white);

            &:hover,
            &:focus {
                color: var(--salmon);
            }
        }
    }

    #login_error,
    .message,
    .success {
        border-color: var(--utility-red);
        color: var(--gray-700);
    }
}
