:root {
    // Neutrals
    --black: rgba(0, 0, 0, 1);
    --black-5pct: rgba(0, 0, 0, 0.05);
    --black-10pct: rgba(0, 0, 0, 0.1);
    --black-20pct: rgba(0, 0, 0, 0.2);
    --black-30pct: rgba(0, 0, 0, 0.3);
    --black-40pct: rgba(0, 0, 0, 0.4);
    --black-50pct: rgba(0, 0, 0, 0.5);
    --black-60pct: rgba(0, 0, 0, 0.6);
    --black-70pct: rgba(0, 0, 0, 0.7);
    --black-80pct: rgba(0, 0, 0, 0.8);
    --black-90pct: rgba(0, 0, 0, 0.9);

    --gray-100: #f8f8f8;
    --gray-200: #d8d8d8;
    --gray-400: #a9a9a9;
    --gray-600: #57585A;
    --gray-700: #282828;

    --white: rgba(255, 255, 255, 1);
    --white-5pct: rgba(255, 255, 255, 0.05);
    --white-10pct: rgba(255, 255, 255, 0.1);
    --white-20pct: rgba(255, 255, 255, 0.2);
    --white-30pct: rgba(255, 255, 255, 0.3);
    --white-40pct: rgba(255, 255, 255, 0.4);
    --white-50pct: rgba(255, 255, 255, 0.5);
    --white-60pct: rgba(255, 255, 255, 0.6);
    --white-70pct: rgba(255, 255, 255, 0.7);
    --white-80pct: rgba(255, 255, 255, 0.8);
    --white-90pct: rgba(255, 255, 255, 0.9);

    // Theme
    --blue-dark: rgba(20, 43, 82, 1);
    --blue-dark-80pct: rgba(20, 43, 82, 0.8);
    --blue-dark-70pct: rgba(20, 43, 82, 0.7);
    --blue-dark-30pct: rgba(20, 43, 82, 0.3);
    --blue-med: rgba(1, 113, 149, 1);
    --blue-light: rgba(70, 167, 183, 1);
    --blue-xlight: rgba(204, 227, 234, 1);
    --sand-dark: rgba(148, 140, 122, 1);
    --sand-light: rgba(242, 240, 225, 1);
    --sand-xlight: rgba(250, 248, 240, 1);
    --mint: rgba(226, 249, 241, 1);
    --salmon: rgba(255, 153, 135, 1);
    
    // Utility
    --utility-red: #a50034;
    --utility-purple: #402885;

    // Gradients


    // Fonts, weights
    --font-sans: "brother-1816", sans-serif;
    --fontweight-normal: 400;
    --fontweight-medium: 500;
    --fontweight-bold: 700;
    --fontsize-h1: clamp(3.5rem, 6.25vw, 5rem);
    --fontsize-h2: clamp(2rem, 3.5vw, 2.875rem);
    --fontsize-h3: clamp(1.75rem, 4vw, 2rem);
    --fontsize-h4: clamp(1.75rem, 4vw, 2rem);
    --fontsize-h5: clamp(1.375rem, 3vw, 1.625rem);
    --fontsize-h6: clamp(1.375rem, 3vw, 1.625rem);
    --fontsize-p-xl: clamp(1.25rem, 1.75vw, 1.5rem);
    --fontsize-p-lg: clamp(1.125rem, 1.5vw, 1.25rem);
    --fontsize-p: rem(16px);

    // Typography, links
    --text-color: var(--blue-dark);
    --link-color: var(--blue-dark);
    --link-color-hover: var(--blue-med);
    --link-color-visited: var(--utility-purple);
    
    // Focus
    --focus-outline-color: var(--blue-light);

    // Spacing
    --block-spacing-sm: 4rem;
    --block-spacing-md: 6rem;
    --block-spacing-lg: 8rem;
    --block-spacing-xl: 10rem;
    --block-spacing-sm-neg: -4rem;
    --block-spacing-md-neg: -6rem;
    --block-spacing-lg-neg: -8rem;
    --block-spacing-xl-neg: -10rem;

    // Misc
    --border-radius: 0.375rem;
}

// Breakpoints
$bp-xs: 30em;  // 480px
$bp-sm: 40em;  // 640px
$bp-md: 50em;  // 800px
$bp-lg: 60em;  // 960px
$bp-xl: 80em;  // 1280px
$bp-xxl: 90em; // 1440px