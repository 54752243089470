/*======================================
    Buttons
======================================*/

%btn {
    --btn-bg-color: var(--blue-light);
    --btn-bg-color-hover: var(--salmon);
    --btn-border-color: var(--blue-light);
    --btn-border-color-hover: var(--salmon);
    --btn-border-radius: 0;
    --btn-text-color: var(--white);
    --btn-text-color-hover: var(--blue-dark);

    background-color: var(--btn-bg-color);
    border: 1px solid var(--btn-border-color);
    border-radius: var(--btn-border-radius);
    color: var(--btn-text-color);
    cursor: pointer;
    display: inline-block;
    font-size: 1rem;
    font-weight: var(--fontweight-medium);
    line-height: 1;
    margin: 0 1em 1em 0;
    padding: 0.75em 1em 0.6875em;
    text-align: center;
    text-decoration: none;
    transition: background-color 250ms ease, border-color 250ms ease, color 250ms ease;
    white-space: nowrap;

    &:hover,
    &:focus-visible {
        background-color: var(--btn-bg-color-hover);
        border-color: var(--btn-border-color-hover);
        color: var(--btn-text-color-hover);
    }
    
    &:focus-visible {
        outline: 2px solid var(--blue-light);
        outline-offset: 2px;
    }
}

%btn-white {
    --btn-bg-color: var(--white);
    --btn-bg-color-hover: var(--salmon);
    --btn-border-color: var(--white);
    --btn-border-color-hover: var(--salmon);
    --btn-text-color: var(--blue-med);
    --btn-text-color-hover: var(--blue-dark);
}

%btn-mint {
    --btn-bg-color: var(--mint);
    --btn-bg-color-hover: var(--mint);
    --btn-border-color: var(--mint);
    --btn-border-color-hover: var(--mint);
    --btn-border-radius: 0;
    --btn-text-color: var(--blue-dark);
    --btn-text-color-hover: var(--blue-dark);
}

.btn {
    @extend %btn;

    &-white {
        @extend %btn-white;
    }

    &-search {
        background-color: transparent;
        background-image: url('../images/ui/search-blue-dark.svg');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        border: 0;
        min-width: 3rem;
        overflow: hidden;
        text-indent: -9999px;
        padding: 0;
        height: 1.5rem;
        width: 1.5rem;
        
        &:hover,
        &:focus-visible {
            background-color: transparent;
        }
    }
    
    &-login {
        @extend %btn-mint;
        border: 0;
        font-weight: var(--fontweight-bold);
        min-width: 3rem;
        padding-inline: 1.5rem;
        display: flex;
        
        &:focus-visible {
            outline: 2px solid var(--blue-light);
            outline-offset: 2px;
        }
        
        &::after {
            display: block;
            content: '';
            background-image: url('../images/ui/login-blue-dark.svg');
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
            height: 1em;
            width: 1em;
            margin-left: 0.5rem;
        }
    }

    .centered & {
        margin: 0 0.5rem 0.5rem;
    }

    &[disable],
    &.slick-disabled {
        --btn-bg-color: var(--gray-400);
        --btn-border-color: var(--gray-600);
        --btn-text-color: var(--gray-600);
        --btn-bg-color-hover: var(--gray-400);
        --btn-border-color-hover: var(--gray-400);
        --btn-text-color-hover: var(--gray-600);

        cursor: default;
        pointer-events: none;
        touch-action: none;
    }
}
